import React, { useState, useContext, useEffect } from 'react';
import { useLocation} from 'react-router-dom';
import { SET_OFFERS } from '../redux/reducers/offer';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
//app components
import LoginModal from '../loginModal';
import RecipeContent from '../recipes/recipe.js';
import Offers from '../offers';
import RegistrationBlock from '../registration';
import Hero from '../hero';
import Content from '../content';
import Cart from '../cart';
import Recipes from '../recipes';
import Footer from '../footer';
import Spinner from '../common/Spinner';
import ErrorToast from '../common/ErrorToast';
import PageNotFound from '../404';

//styles
import '../App.css';
import { SiteConfigContext } from '../SiteConfigContext';

// require (`../styles/${process.env.REACT_APP_BRAND_NAME}/styles.scss`);


function SitePage(props) {
  const siteConfig = useContext(SiteConfigContext);
  const {
      headerProperties,
      siteName,
      brandName,
      colorBackground,
      hasContent,
      hasCartItems,
      hasRecipes,
      hasFooter,
      hasChat,
      chatbotPageId,
      ga,
      inmarAnalyticsId,
      fbPixel,
      fbValidation,
      backgroundImage,
      } = siteConfig;
    
      useEffect(() => {
      import(`../styles/${siteConfig.siteName}/${siteConfig.brandName}/styles.scss`);
    }, [siteConfig.brandName]);

  const dispatch = useDispatch();
  const {
    config,
    config: {
      retailer: {
        dpn: { id: dpn_id },
      },
      urlParam: { ids: offerIds },
    },
  } = useSelector((state) => state);
  let mpidbyEnv = config.myEnv;
  let [isLoading, updateLoadingState] = useState(true);
  let [isFetching, updateFetchState] = useState(false);
  let [storedClips, updateStoredClips] = useState({ list: [] });
  let [recipeData, setRecipes] = useState([]);
  let [hero, setHero] = useState([]);
  let offers = {
    clipped: [],
    available: [],
    featured: []
  };

  // let mediaPId = config.retailer.mpid[mpidbyEnv],
  //   showLogin = useSelector((state) => state.offer.show_login),
  //   featuredOffers = config.urlParam.fid || config.urlParam.FID;
  let mediaPId = siteConfig.mpId;
  const hostname = window.location.hostname;
  const isLocalhost = hostname.includes('localhost');
  const devShowAllOffers = false;
  let showLogin = useSelector((state) => state.offer.show_login),
    featuredOffers = config.urlParam.fid || config.urlParam.FID;
  function parseOffers(data) {
    let storage = localStorage.getItem('pbyt');
    if (storage) {
      const storedObj = JSON.parse(storage);

      updateStoredClips({ list: storedObj.myIds ? storedObj.myIds : [] });
    }
    let parsed = offers;
    if (data) {
      data.forEach((offer, idx) => {
        const display_start_date = new Date(offer.clipStartDate.iso);
        const display_end_date = new Date(offer.clipEndDate.iso);
        offer.display_start_date = display_start_date;
        offer.display_end_date = display_end_date;
        if (storedClips.list.includes(offer.mdid)) {
          Object.assign(offer, { clipped: true });
        }
        if (
          featuredOffers &&
          featuredOffers.includes(offer.mdid)
        ) {
          parsed.featured.push(offer);
        } else {
          parsed.available.push(offer);
        }
      });
    }
    dispatch(SET_OFFERS({ collections: parsed }));
    return true;
  }
  function fetchOffers() {
    //prevent refetch if already started
    updateFetchState(true);

    let url = 'offers';
    if (config.urlParam.testing) {
      console.log("TESTING")
      url = '/json/coupons.json';
    }

    let urlObject = {};
    if (offerIds && !featuredOffers) {
      urlObject.ids = offerIds;
    } else if (mediaPId) {
      urlObject.mediaPropertyId = mediaPId;
    }
    if (isLocalhost && devShowAllOffers) {
      urlObject.mediaPropertyId = '';
    }
    url += '?limit=99&' + queryString.stringify(urlObject);
    fetch(config.baseUrl + url, {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': dpn_id,
        Origin: window.location.origin,
        'If-Modified-Since': new Date(),
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.length > 0) {
          parseOffers(data);
        }
      })
      .then(() => {
        updateLoadingState(false);
      });
  }
  function fetchRecipes(){
    let recipeInfo = '/json/' + siteName + '/' + brandName + '/recipe.json';
    
    fetch( recipeInfo, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    } )
    .then(function(response){
      return response.json();
    })
    .then(function(data) {
      setRecipes(data)
    });
    
  }
  // function fetchHero(){
  //   let heroInfo = '/json/' + siteName + '/' + brandName + '/hero.json';
    
  //   fetch( heroInfo, {
  //     headers : { 
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json'
  //      }
  //   } )
  //   .then(function(response){
  //     return response.json();
  //   })
  //   .then(function(data) {
  //     setHero(data)
  //   });
    
  // }
  function ModalBackdrop() {
    if (showLogin) {
      return <div className="modal-backdrop fade show"></div>;
    } else {
      return '';
    }
  }
  function googleAnalytics() {
    var gaId = ga;

    var galink = document.createElement('script');
    galink.src = 'https://www.googletagmanager.com/gtag/js?id='+gaId;
    
    var galink2 = document.createElement('script');
    galink2.innerHTML =
      'window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag(\'js\', new Date()); gtag(\'config\', \'' + gaId + '\');'

    if(gaId){
      document.head.appendChild(galink);
      document.head.appendChild(galink2);
    }
    galink.addEventListener('load', function () {
      // document.head.appendChild(galink2);
    });
  }
  function inmarAnalytics() {
    var iaId = inmarAnalyticsId;

    var ialink = document.createElement('script');
    ialink.innerHTML = 'window._oiqq = window._oiqq || [];  _oiqq.push([\'oiq_addPageLifecycle\', \'' + iaId + '\']); _oiqq.push([\'oiq_doTag\']); (function() { var oiq = document.createElement(\'script\'); oiq.type = \'text/javascript\'; oiq.async = true; oiq.src = \'https://px.owneriq.net/stas/s/br53cg.js\'; var s = document.getElementsByTagName(\'script\')[0]; s.parentNode.insertBefore(oiq, s);})();';
    
    if (iaId){
      document.head.appendChild(ialink);
    }
  }
  function facebookPixel() {
    var fbPixel = fbPixel;

    var fblink = document.createElement('script');
    fblink.innerHTML = '!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,\'script\', \'https://connect.facebook.net/en_US/fbevents.js\'); fbq(\'init\', \''+ fbPixel +'\');fbq(\'track\', \'PageView\');';
    
    var fblink2 = document.createElement('noscript');
    fblink2.innerHTML = '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id='+fbPixel+'&ev=PageView&noscript=1"/>';

    if (fbPixel){
      document.head.appendChild(fblink);
      document.head.appendChild(fblink2);
    }
  }
  function facebookValidation() {
    var fbValidationID = fbValidation;

    var fbValidation = document.createElement('meta');
    fbValidation.setAttribute('name',"facebook-domain-verification");
    fbValidation.setAttribute('content',fbValidationID)
    
    if (fbValidationID){
      document.head.appendChild(fbValidation);
    }
  }

  function addMetaData() {
  
    var keywordsMeta = document.createElement('meta');
    keywordsMeta.setAttribute('name', 'keywords');
    keywordsMeta.setAttribute('content', headerProperties.keywords);
  
    var head = document.head;
    head.insertBefore(keywordsMeta, head.firstChild);
  
    var title = document.createElement('meta');
    title.setAttribute('property', 'og:title');
    title.setAttribute('content', headerProperties.ogTitle);
    head.appendChild(title);
  
    var url = document.createElement('meta');
    url.setAttribute('property', 'og:URL');
    url.setAttribute('content', headerProperties.ogURL);
    head.appendChild(url);
  
    var description = document.createElement('meta');
    description.setAttribute('name', 'description');
    description.setAttribute(
      'property',
      'og:description'
      );
    description.setAttribute('content', headerProperties.ogDescription)
    head.appendChild(description);
  
    var image = document.createElement('meta');
    image.setAttribute('property', 'og:image');
    image.setAttribute(
      'content',
      headerProperties.ogImage
    );
    head.appendChild(image);

    var themeColor = document.createElement('meta');
    themeColor.setAttribute('name', 'theme-color');
    themeColor.setAttribute('content', headerProperties.themeColor);
    head.appendChild(themeColor);
  
    var fbDomainVerification = document.createElement('meta');
    fbDomainVerification.setAttribute(
      'name',
      'facebook-domain-verification'
    );
    fbDomainVerification.setAttribute(
      'content',
      headerProperties.fbDomainVerification
    );
    head.appendChild(fbDomainVerification);
  
    var titleTag = document.createElement('title');
    titleTag.innerHTML = headerProperties.ogTitle;
    head.appendChild(titleTag);
  }
  


  const appClasses = `App ${showLogin ? 'modal-open' : ''}`;
  if (!isFetching) {
    fetchOffers();
    // fetchHero();
    addMetaData();
    googleAnalytics();
    inmarAnalytics();
    facebookPixel();
    facebookValidation();
    
    if(hasRecipes){
      fetchRecipes();
    }
  }
  if (isLoading) {
    return <Spinner />;
  } else {

    const showContent = hasContent === true;
    const showCart = hasCartItems === true;
    const showRecipes = hasRecipes === true;
    const showFooter = hasFooter === true;

    const brand = brandName;
    const imgPath = '/images/' + brand + '/' + backgroundImage; 
    
  return (
    <div
      className={('App ', appClasses)}
      id="App"
      style={{
        backgroundColor: colorBackground,
        backgroundImage: "url(" + imgPath + ")",
      }}
    >
      <LoginModal />
      <div
        className={(`App-content container ${brandName}`)}
        id="App-content"
      >
        <div id="fb-root"></div>
        <div id="fb-customer-chat" className="fb-customerchat"></div>
        
          {/* <Route path="/" element={
                <> */}
          <Hero hero={hero}/>
          {/* <RegistrationBlock offers={offers} />
          <Offers offers={offers} />
          {showCart && <Cart />}
          {showRecipes && <Recipes recipes={recipeData}/>}
          {showContent && <Content />}
          {showFooter && <Footer />} */}
                {/* </>
              } /> */}
          {/* <Route path="recipes/:recipeName" element={<RecipeContent recipes={recipeData} />} />
          <Route path="*" element={<PageNotFound />} /> */}
        
        <ErrorToast />
      </div>
      {/* {hasChat && <Messenger></Messenger>} */}
      <ModalBackdrop></ModalBackdrop>
    </div>
  );
  }
}
export default SitePage;
