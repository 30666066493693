import React, { useContext } from 'react';

import './footer.scss';
import { SiteConfigContext } from '../SiteConfigContext';

function Footer() {
  const siteConfig = useContext(SiteConfigContext);
  const brand = siteConfig.siteName + '/' + siteConfig.brandName
  let path = '/images/' + brand + '/publix_exclusive-logo.png';

  return (
    <div className="footer-content" style={{ backgroundColor: siteConfig.colorBackground }}>
      <img
        className={`footer-Img`}
        src={path}
        alt={siteConfig.footerAlt}
      /> 
      <p className={`footer-text`}></p>
    </div>
  );
}
export default Footer;
