import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_USER, CLEAR_USER } from '../redux/reducers/user';

import './registration.scss';
import { SiteConfigContext } from '../SiteConfigContext';

function RegistrationBlock(props) {
  const siteConfig = useContext(SiteConfigContext);
  const dispatch = useDispatch();
  let username = useSelector((state) => state.user.username);
  const storage = localStorage.getItem('pbyt');
  let links = siteConfig.colorLinks;

  if (!username && storage) {
    const storedUn = JSON.parse(storage).username;
    if (storedUn) {
      username = storedUn;
      dispatch(SET_USER({ state: 2, username: username }));
    }
  }

  function clearUser() {
    dispatch(CLEAR_USER());
    window.location.reload();
  }

  function RenderClearUser() {
    if (username) {
      return (
        <div>
          Not you?
          <button onClick={clearUser} style={{ color: links }}>
            Forget User
          </button>
        </div>
      );
    }
    return '';
  }

  function trackRegistrationEvent (){
    window.gtag('event', 'registration_link_click', {  });
  }

  return (
      <section className="register-bar">
        {!username && (
          <span>
            Not a registered Publix user yet?{' '}
            <a
              href="https://www.publix.com/myaccount/register"
              onClick={trackRegistrationEvent}
              target="_blank"
              style={{ color: links }}
              rel="noopener noreferrer"
            >
              Click here
            </a>
          </span>
        )}
        <RenderClearUser />
      </section>
  );
}

export default RegistrationBlock;
