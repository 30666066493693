import React, {useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { SiteConfigContext } from '../SiteConfigContext';

import './hero.scss';

function Hero() {
  //props fetch data from hero.json
  // const brand = process.env.REACT_APP_BRAND_NAME;
  const siteConfig = useContext(SiteConfigContext);
  const imgPath = '/images/' + siteConfig.siteName + '/' + siteConfig.brandName + '/'; 
  const [width, setWidth] = useState(window.innerWidth);

  const gardenBG = siteConfig.hero.garden[0].background.hasBG === 'true' ? imgPath + siteConfig.hero.garden[0].background.image : '';
  const gardenStyle = {
    backgroundImage: "url(" + gardenBG + ")"
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  //switch to hero mobile image if screen is less than 768px width
  const isMobile = width <= 768;

  function StaticHero(){
    const hero = siteConfig.hero.staticHero;
    return(
      <img
        className={`heroImg`}
        src={imgPath + (!isMobile ? hero.image : hero.mobile)}
        alt={hero.alt}
      />
    )
  }
  function HeroCarousel() {
    const heroCarousel = siteConfig.hero.carouselHero;
    return (
      <Carousel
        autoPlay={true}
        interval={5000}
        infiniteLoop={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        swipeable={true}
        onClickItem={anchorItem}
      >
        {heroCarousel.map((item, i) => {
          return(
            <a href={item.anchor} key={i}>
              <img
                className={`heroImg`}
                src={imgPath + (!isMobile ? item.image : item.mobile)}
                alt={item.alt}
              />
            </a>
          )
        })}
      </Carousel>
    );
  }
  function anchorItem(i,child){
    if(child.props.href){
      window.location.href = child.props.href;
    }
  }
  function GardenImages() {
    const garden = siteConfig.hero.garden[0].icons;
    return (
      <div>
        {garden.map((item, i) => {
          if (item.link) {
            return (
              <a href={item.link} key={i}>
                <img
                  className={`gardenImg`}
                  src={imgPath + item.image}
                  alt={item.alt}
                />
              </a>
            );
          } else {
            return (
              <a href={item.link} target="_Blank" rel="noopener noreferrer" key={i}>
              <img
                className={`gardenImg`}
                src={imgPath + item.image}
                alt={item.alt}
              />
            </a>
            );
          }
        })}
      </div>
    );
  }
  function GardenImages2(){
    const garden = siteConfig.hero.garden2[0].icons;
    return (
      <div>
        {garden.map((item, i) => {
          return(
            <a href={item.link} target="_Blank" rel="noopener noreferrer" key={i}>
              <img
                className={`gardenImg`}
                src={imgPath + item.image}
                alt={item.alt}
              />
            </a>
          )
        })}
      </div>
    )
  }
  return (
    
    <div className='header'>
      <div className="hero">
        { siteConfig.hero.carouselHero && <HeroCarousel/> }
        { siteConfig.hero.staticHero && <StaticHero/> }
      </div>
      {/* <div className='garden' style={gardenStyle}>
        <GardenImages/>
        {siteConfig.secondGarden && <GardenImages2/>}
      </div> */}
    </div>
  );
}
export default Hero;
