import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import SitePage from './site';
// import Messenger from './messenger';
import { SiteConfigProvider } from './SiteConfigContext';
import site_config from './site-config.json'; // This is assuming site-config.json is in your src/config directory
//styles
import './App.css';


function App() {
  return (
    <div>
      <BrowserRouter >
        <Routes>
          <Route 
              path='/'
              element={
                <SiteConfigProvider config={site_config.main}>
                  <SitePage />
                </SiteConfigProvider>
              } 
            />
          <Route 
              path='/NestleHealthScience'
              element={
                <SiteConfigProvider config={site_config.brandSites[1]} >
                  <SitePage />
                </SiteConfigProvider>
              } 
            />
          <Route 
              path='/siggis'
              element={
                <SiteConfigProvider config={site_config.brandSites[2]} >
                  <SitePage />
                </SiteConfigProvider>
              } 
            />
          <Route 
              path='/stonyfield'
              element={
                <SiteConfigProvider config={site_config.brandSites[3]} >
                  <SitePage />
                </SiteConfigProvider>
              } 
            />
          <Route 
              path='/kelloggs'
              element={
                <SiteConfigProvider config={site_config.brandSites[4]} >
                  <SitePage />
                </SiteConfigProvider>
              } 
            />
            <Route 
              path='/generalmills'
              element={
                <SiteConfigProvider config={site_config.brandSites[5]} >
                  <SitePage />
                </SiteConfigProvider>
              } 
            />


        </Routes>
      </BrowserRouter>
      {/* </SiteConfigProvider> */}
      {/* {hasChat && <Messenger></Messenger>} */}
    </div>
    );
  }

export default App;
